import { NextComponentType } from 'next'
import Layout from '../../containers/Layout'

export default (ComposedComponent: NextComponentType) => (props: any) => {
  return (
    <Layout>
      <ComposedComponent {...props} />
    </Layout>
  )
}
