// import { createStyles, makeStyles, Theme } from '@mui/material'
// import { MadeWithLove } from '@novafuturltd/reusable/components/common/made.with.love'
import { useWindowSize } from '@novafuturltd/shared'
import { FC, useEffect } from 'react'
import { useAppContext } from '@novafuturltd/core'

interface Props {}

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     madeRoot: {
//       display: 'flex',
//       justifyContent: 'flex-end',
//       position: 'absolute',
//       bottom: theme.spacing(6),
//       right: theme.spacing(6),
//     },
//     madeContent: {
//       background: theme.palette.common.white,
//       padding: theme.spacing(1),
//       borderRadius: theme.spacing(1),
//     },
//   }),
// )
// tslint:disable-next-line: no-shadowed-variable
const Header: FC<Props> = ({ children }) => {
  // tslint:disable-next-line: no-shadowed-variable
  const { toggleAll, height } = useAppContext()
  const { width, height: windowHeight } = useWindowSize()
  // const classes = useStyles({})

  useEffect(() => {
    if (height === 0) {
      toggleAll(width, windowHeight)
    }
  })
  return (
    <div>
      {children}
      {/* <div className={classes.madeRoot}>
        <div className={classes.madeContent} />
      </div> */}
    </div>
  )
}

export default Header
