import { NextComponentType } from 'next'
import React from 'react'

export default (ComposedComponent: NextComponentType) => (props: any) => {
  return (
    <>
      <ComposedComponent {...props} />
    </>
  )
}
